// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfintegrationwithaiforsuggestingtravelrequests from "../../blocks/cfintegrationwithaiforsuggestingtravelrequests/src/Cfintegrationwithaiforsuggestingtravelrequests";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Analytics from "../../blocks/analytics/src/Analytics";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Translation from "../../blocks/translation/src/Translation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import Cfapiintegrationwithvisapartner1 from "../../blocks/cfapiintegrationwithvisapartner1/src/Cfapiintegrationwithvisapartner1";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Videos from "../../blocks/videos/src/Videos";
import Cfairlinevendorintegration from "../../blocks/cfairlinevendorintegration/src/Cfairlinevendorintegration";
import Trending from "../../blocks/trending/src/Trending";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Keywordextractor2 from "../../blocks/keywordextractor2/src/Keywordextractor2";
import Cftaxivendorintegration from "../../blocks/cftaxivendorintegration/src/Cftaxivendorintegration";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfapiintergationforaigeneratedtours from "../../blocks/cfapiintergationforaigeneratedtours/src/Cfapiintergationforaigeneratedtours";
import Reservations from "../../blocks/reservations/src/Reservations";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Travelguide2 from "../../blocks/travelguide2/src/Travelguide2";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Cfapiintegrationforaithatwilltakevoiceprompt from "../../blocks/cfapiintegrationforaithatwilltakevoiceprompt/src/Cfapiintegrationforaithatwilltakevoiceprompt";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Share from "../../blocks/share/src/Share";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfhotelvendorintegration from "../../blocks/cfhotelvendorintegration/src/Cfhotelvendorintegration";
import PricingEngine from "../../blocks/pricingengine/src/PricingEngine";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Multiplegesturerecognisers2 from "../../blocks/multiplegesturerecognisers2/src/Multiplegesturerecognisers2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Cfintegrationwithaiforsuggestingtravelrequests:{
 component:Cfintegrationwithaiforsuggestingtravelrequests,
path:"/Cfintegrationwithaiforsuggestingtravelrequests"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Translation:{
 component:Translation,
path:"/Translation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
Cfapiintegrationwithvisapartner1:{
 component:Cfapiintegrationwithvisapartner1,
path:"/Cfapiintegrationwithvisapartner1"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Videos:{
 component:Videos,
path:"/Videos"},
Cfairlinevendorintegration:{
 component:Cfairlinevendorintegration,
path:"/Cfairlinevendorintegration"},
Trending:{
 component:Trending,
path:"/Trending"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Maps:{
 component:Maps,
path:"/Maps"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Keywordextractor2:{
 component:Keywordextractor2,
path:"/Keywordextractor2"},
Cftaxivendorintegration:{
 component:Cftaxivendorintegration,
path:"/Cftaxivendorintegration"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfapiintergationforaigeneratedtours:{
 component:Cfapiintergationforaigeneratedtours,
path:"/Cfapiintergationforaigeneratedtours"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Travelguide2:{
 component:Travelguide2,
path:"/Travelguide2"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Cfapiintegrationforaithatwilltakevoiceprompt:{
 component:Cfapiintegrationforaithatwilltakevoiceprompt,
path:"/Cfapiintegrationforaithatwilltakevoiceprompt"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Share:{
 component:Share,
path:"/Share"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfhotelvendorintegration:{
 component:Cfhotelvendorintegration,
path:"/Cfhotelvendorintegration"},
PricingEngine:{
 component:PricingEngine,
path:"/PricingEngine"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Multiplegesturerecognisers2:{
 component:Multiplegesturerecognisers2,
path:"/Multiplegesturerecognisers2"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;